import React, {useState} from "react";
import {NavLink} from "react-router-dom";

import {authPages, notAuthPages} from "./routing/config";
import {
    IdcardOutlined,
    FileDoneOutlined,
    LogoutOutlined,
    DashboardOutlined,
    ShoppingCartOutlined,
    MedicineBoxOutlined
} from "@ant-design/icons";
import {Layout, Col, Row} from "antd";

import {LOGO} from './assets/images'
import {Nav, MobileNav, MobileMenuDrawer} from "./components/Navigation";
import SiderLogo, {LogoImg} from "./components/SiderLogo";
import {useNavigate} from "react-router-dom";

const {Sider} = Layout

const {
    logout,
} = notAuthPages;

const {
    home,
    myInformation,
    newOrder,
    myResults,
    activateKit,
} = authPages;

const getItem = (label, key, icon, children) => ({key, icon, children, label,});

const patients_menu_items = [
    /* Menu builder */
    getItem(home.title, home.url, <DashboardOutlined style={{fontSize: '130%', marginRight: 20}}/>),
    getItem(activateKit.title, activateKit.url, <MedicineBoxOutlined style={{fontSize: '130%', marginRight: 20}}/>),
    getItem(myResults.title, myResults.url, <FileDoneOutlined style={{fontSize: '130%', marginRight: 20}}/>),
    getItem(newOrder.title, newOrder.url, <ShoppingCartOutlined style={{fontSize: '130%', marginRight: 20}}/>),
    getItem(myInformation.title, myInformation.url, <IdcardOutlined style={{fontSize: '130%', marginRight: 20}}/>),
    getItem(logout.title, logout.url, <LogoutOutlined style={{fontSize: '130%', marginRight: 20}}/>),
];

const DesktopNavigation = () => {
    /* Main navigation component. Handle all main pages in application. Extends ant `Menu` component. */

    const navigate = useNavigate();
    const [collapsed, setCollapsed] = useState(false);

    const mainMenuHandler = (args) => {
        /* Handle all clicks and navigate to needed page */
        const {keyPath} = args;
        const [url] = keyPath;
        navigate(url);
    }

    return <Row>
        <Col xs={0} sm={0} md={24} lg={24}>
            <Sider
                width={'260px'}

                style={{
                    background: '#11242f',
                    overflow: 'auto',
                    height: '100vh',
                    position: 'fixed',
                    left: 0,
                    top: 0,
                    bottom: 0,
                }}
                collapsed={collapsed}
                onCollapse={(value) => setCollapsed(value)}
            >
                <NavLink to={home.url}>
                    <SiderLogo
                        image_source={LOGO}
                    />
                </NavLink>

                <Nav
                    theme="dark"
                    defaultSelectedKeys={['1']}
                    mode="inline"
                    items={patients_menu_items}
                    onClick={mainMenuHandler}
                />

            </Sider>
        </Col>
    </Row>
}


const MobileNavigation = ({open, onClose}) => {

    /* Mobile navigation component.
    Handle all main pages in application.
    Extends from `ant `Drawer` and `Menu` components. */
    const navigate = useNavigate();

    const mainMenuHandler = (args) => {
        /* Handle all clicks and navigate to needed page */
        const {keyPath} = args; // getting current `key` attr in clicked Menu Item
        const [url] = keyPath;

        onClose();  // close MobileMenu Drawer
        navigate(url);  // use `key` attr value for routing
    }

    // fix bug on mobile devices with scroll
    if (open) {
        document.body.style.overflow = "hidden";
    } else {
        document.body.style.overflow = "auto";
    }


    return <MobileMenuDrawer
        width={'82%'} placement="right" title={null} closable={false}
        onClose={onClose} open={open} destroyOnClose={true} maskClosable={true}
        zIndex={1000} getContainer={false}
    >

        {/*Drawer value */}
        <MobileNav theme="dark" mode="inline" items={patients_menu_items} onClick={mainMenuHandler}/>
        <LogoImg src={LOGO}/>

    </MobileMenuDrawer>
}


export {
    DesktopNavigation, MobileNavigation,
}