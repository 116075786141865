import React, {useState, useContext, useRef, useLayoutEffect, useEffect} from 'react';
import {Outlet, NavLink, useLocation} from "react-router-dom";

import {DesktopNavigation, MobileNavigation} from "./Navigation";
import {MainLayoutWrapper, MainContentBlock} from "./components/MainContentBlock";
import HeaderLogoOnMobile from "./components/HeaderLogoOnMobile";

import {AlignRightOutlined, CopyrightOutlined} from "@ant-design/icons";
import {Layout, Row, Col} from "antd";

import {authPages, notAuthPages} from "./routing/config";
import {LOGO} from "./assets/images";
import {AuthContext} from "./pages/auth/AuthContext";
import TaskStatus from "./pages/tasks/TaskStatusContext/TaskStatus";


const {Header, Footer} = Layout;

const MainLayout = () => {
    /* First rendering component in Router config. UI main entrypoint  */

    const [shouldUpdateFooter, setShouldUpdateFooter] = useState(false);
    const layoutRef = useRef(null);
    const mainContentRef = useRef(null);
    const footerRef = useRef(null);

    const [open, setOpen] = useState(false);
    const {authContext} = useContext(AuthContext);
    const {pathname} = useLocation();


    useLayoutEffect(() => {
        const updateFooterPosition = () => {
            setTimeout(() => {
                const mainContentHeight = mainContentRef.current?.clientHeight || 0;
                const footerHeight = footerRef.current?.clientHeight || 0;
                const viewportHeight = window.innerHeight;

                const remainingSpace = viewportHeight - mainContentHeight - footerHeight;

                if (footerRef.current && Reflect.has(footerRef.current, 'style')) {
                    footerRef.current.style.marginTop = remainingSpace > 0 ? `${remainingSpace}px` : '0';
                }

            }, 50); // delay of 100 milliseconds
        }

        const observer = new MutationObserver(updateFooterPosition);
        // Observe changes in Layout
        if (mainContentRef?.current && layoutRef?.current) {
            observer.observe(mainContentRef?.current, {childList: true, subtree: true});
            observer.observe(layoutRef?.current, {childList: true, subtree: true});
            window.addEventListener('resize', updateFooterPosition);

            return () => {
                observer.disconnect();
                window.removeEventListener('resize', updateFooterPosition);
            };
        }
    }, [shouldUpdateFooter, pathname]);

    const triggerFooterUpdate = () => {
        // Re-render footer when we change the page
        setShouldUpdateFooter(prevState => !prevState);
    };


    useEffect(() => {
        triggerFooterUpdate();
    }, [authContext, pathname]);


    /* ==================Important===================
    * This code running before render first content on page and fix render problems.
    * At the bottom we can see all default pages, what works without Auth.
    * */
    if (authContext === null && pathname === notAuthPages.resetPassword.url) {
        return notAuthPages.resetPassword.jsx
    }

    if (authContext === null && pathname === '/') {
        return notAuthPages.loginDefault.jsx
    }
    /* ========= Default pages controller ========= */

    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };


    return <Layout style={{overflow: "hidden"}} ref={layoutRef}>


        {/*Register background tasks --- START*/}
        <TaskStatus/>
        {/*Register background tasks --- END */}

        {/*Main navigation guys*/}
        <MobileNavigation onClose={onClose} open={open}/>
        <DesktopNavigation/>
        {/*Main navigation guys*/}


        {/*Main content Layout --- START*/}
        <MainLayoutWrapper>

            {/* Header used for Mobile only --- START */}
            <Row>
                <Col md={0} xs={24}>
                    <Header style={{overflow: "hidden", height: "auto", padding: 0}}>
                        <div style={{display: "flex", alignContent: 'end', justifyContent: 'end'}}>
                            <NavLink to={authPages.home.url}>
                                <HeaderLogoOnMobile image_source={LOGO}/>
                            </NavLink>

                            <AlignRightOutlined
                                onClick={showDrawer}
                                style={{
                                    color: 'white', fontSize: '240%', padding: '25px 20px 0 0', cursor: "pointer"
                                }}
                            />
                        </div>
                    </Header>
                </Col>
            </Row>
            {/* Header used for Mobile only --- END*/}

            {/* Main block for any pages in our app --- START*/}
            <MainContentBlock ref={mainContentRef}>

                {/*{shouldUpdateFooter ? <h1>TRUE</h1> : <h1>FALSE</h1>}*/}

                <Outlet/>
            </MainContentBlock>
            {/* Main block for any pages in our app --- END */}

            <Footer ref={footerRef} style={{textAlign: 'center', fontSize: 12}}>
                Employee portal, Efunctional LLC <CopyrightOutlined style={{fontSize: 12}}/>, 2023
            </Footer>

        </MainLayoutWrapper>
        {/*Main content Layout --- END */}

    </Layout>;
}


export default MainLayout;
