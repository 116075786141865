import {authPages} from "../../../routing/config";
import React from "react";
import {Col, Row, Tabs} from "antd";
import MainPageTitle from "../../../components/MainPageTitle";
import PersonalInformation from "./PersonalInformation";
import ShippingInformation from "./ShippingInformation";
import ChangePassword from "./ChangePassword";


const items = [
    {
        key: '1',
        label: `Personal`,
        children: <PersonalInformation/>,
    },

    {
        key: '2',
        label: `Shipping`,
        children: <ShippingInformation/>,
    },

    {
        key: '3',
        label: `Change password`,
        children: <ChangePassword/>,
    },

    {
        key: '4',
        label: `Payment info`,
        children: `Content of Tab Pane 3`,
        disabled: true
    },
];

const MyInformation = () => <Row>
    <Col xs={24} lg={24} xl={24}>
        <MainPageTitle>{authPages.myInformation.title}</MainPageTitle>
        <Tabs defaultActiveKey="1" items={items}/>
    </Col>
</Row>;

export default MyInformation;