import styled from "styled-components";
import {Menu, Drawer} from "antd";

const Nav = styled(Menu)`

  padding: 30px 0 0 0;
  background: #11242f;


  & .ant-menu-item-icon {
    font-size: 20px;
  }

  & .ant-menu-item {
    padding: 5px 0;
  }


  & .ant-menu-item, .ant-menu-submenu {
    /* all menu items */
    transition: all 0.5s ease;
    font-size: 20px;
    font-weight: 400;
    color: #fff;
    font-family: "Montserrat", sans-serif;


    &:hover {
      cursor: pointer;
      background: #193748 !important;
      opacity: 1;
      border-radius: 0;
    }
  }

  & .ant-menu-item-only-child {
    color: #fff;
    font-size: 15px;
    padding: 5px 0;
    white-space: nowrap;
    opacity: 0.6;
    transition: all 0.3s ease;
  }

  & .ant-menu-item-only-child .ant-menu-title-content {
    margin: 0 0 0 35px;
  }

  & .ant-menu-item-only-child {

  }

  & .ant-menu-item-active {
    &:hover {
      cursor: pointer;
      background: #193748;
      opacity: 1;
    }
  }

  & .ant-menu-item-selected {
    /* Work with selected Menu.Item ant */
    background: transparent;
    opacity: 1;

    &:hover {
      cursor: pointer;
      background: #193748;
      opacity: 1;
    }
  }

  & .ant-menu {
    background: #193748 !important;
  }
`

const MobileNav = styled(Nav)`

`

const MobileMenuDrawer = styled(Drawer)`

  background: #11242f !important;
  position: relative;

  & * {
    transition: all 0.3s ease;
  }

  & .ant-drawer-body {
    padding: 10px;
  }

  & .ant-menu-root {
    margin: 90px 0 0 0;
    padding: 0;
  }

  & img {
    width: 35% !important;
    bottom: 5%;

    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;

  }
`

export {
    Nav,
    MobileNav,
    MobileMenuDrawer,
}