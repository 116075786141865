import restAPI from "../../../features/restAPI";
import {notAuthPages} from "../../../routing/config";

import React, {useState} from 'react';
import {NavLink} from "react-router-dom";

import {UserOutlined} from '@ant-design/icons';
import {Row, Col, Form, Input, Alert} from "antd";

import bg from "../Login/efunc_bg.svg";
import loginLogo from "../Login/login_logo.svg";
import {SubmitButton, ForgotPasswordButton, LogoElement, LoginForm} from "../../../components/Login";


const ResetPassword = () => {

    const {loginDefault,} = notAuthPages;
    const [authError, setAuthError] = useState(null);  // state for login error
    const [isPasswordReset, setIsPasswordReset] = useState(null);
    const [successContent, setSuccessContent] = useState(null);

    const onSubmitHandler = async values => {

        try {
            setAuthError(null);
            // send email with the reset link to user
            const request = await restAPI.post('/reset-password/', values);

            setSuccessContent(request.data.success);
            setIsPasswordReset(true);

        } catch (error) {

            console.log(error)

            // These errors we can get from API
            if (error?.response?.data.hasOwnProperty('error')) {
                setAuthError(error.response.data.error);
                return;
            }

            // Check where we can manage this error
            if (error?.response?.data.hasOwnProperty('detail')) {
                setAuthError(error.response?.data?.detail);
            }
        }
    }

    return <Row justify={"center"} style={{background: `url('${bg}')`, backgroundSize: 'cover', height: "100vh"}}>

        <Col xs={20} sm={16} md={12} lg={10} xl={8} xxl={6}>

            <LoginForm
                autoComplete={'true'}
                name="reset-password"
                initialValues={{remember: true}}
                onFinish={onSubmitHandler}
            >
                <Row justify={"center"}>
                    <LogoElement src={loginLogo} alt=""/>
                </Row>


                <Form.Item
                    name="username"
                    rules={[{required: true, message: 'Please enter username',},
                        {
                            // eslint-disable-next-line
                            pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                            message: 'Please enter valid email',
                        }
                    ]}
                >
                    <Input
                        placeholder="Please enter username"
                        prefix={<UserOutlined style={{color: "#169F8A"}}/>}
                    />
                </Form.Item>


                <Form.Item>
                    <SubmitButton htmlType="submit" type="primary">
                        Reset Password
                    </SubmitButton>
                </Form.Item>

                <Row justify={"center"}>
                    <NavLink to={loginDefault.url} style={{color: 'white'}}>
                        <ForgotPasswordButton type={"link"}>
                            Log In
                        </ForgotPasswordButton>
                    </NavLink>
                </Row>

            </LoginForm>

            {authError && <Alert
                style={{marginTop: 20}}
                message={authError}
                type="error"
                closable
            />}

            {isPasswordReset && <Alert
                style={{marginTop: 20, textAlign: "center"}}
                type={'success'}
                message={successContent}
            />}

        </Col>

    </Row>
}


export default ResetPassword;