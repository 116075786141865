import React, {useState, useEffect} from "react";
import restAPI from "../../../features/restAPI";

import {Col, Row, Button, Radio, Popconfirm} from "antd";

const CheckTestCode = (props) => {
    /* In general, we need check current Kit test kind and use this step */

    const {detectedKitInfo, isLoading, setIsLoading, next, authConfig, detectedProduct, setDetectedProduct} = props;

    const [isSelfPhlebotomy, setIsSelfPhlebotomy] = useState(null);

    const getAllProductsInfo = async () => {
        setIsLoading(true);

        try {

            const response = await restAPI.get(`/products/`, authConfig);
            const detectedProductItem = response.data.results.filter(p => p.code === detectedKitInfo.test);

            if (detectedProductItem.length === 1) {
                setDetectedProduct(detectedProductItem[0]);
            } else {
                throw Error(`We can't find product associated with your Kit. Please call to support.`);
            }

        } catch (error) {

            console.log(error);

        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        (async () => {
            await getAllProductsInfo();
        })();
        // eslint-disable-next-line
    }, []);


    const confirm = (e) => {
        console.log(e);
    };
    const cancel = (e) => {
        console.log(e);
    };

    const IsMicronutrient = () =>

        <Row>
            <Col lg={24}>
                <p>
                    You are about to activate your EFunctional MICRONUTRIENT TEST KIT.
                </p>

                <p>
                    This Test Kit requires a phlebotomist to collect your sample.
                </p>

                <p>
                    <strong>Please select how your sample will be collected?</strong>
                </p>

                <Row style={{margin: '25px 0'}}>
                    <Col>
                        <Radio.Group
                            value={isSelfPhlebotomy} buttonStyle="solid"
                            onChange={event => setIsSelfPhlebotomy(event.target.value)}
                        >
                            <Radio.Button value="false">Schedule a mobile Phlebotomist</Radio.Button>
                            <Radio.Button value="true">My Practitioner will collect</Radio.Button>
                        </Radio.Group>
                    </Col>
                </Row>

                <Row>
                    {isSelfPhlebotomy === "true"
                        ? <Col>
                            <p>
                                Your <strong>Provider</strong> will collect your sample and record
                                the collection <strong>date and time</strong>.
                            </p>

                            <p>
                                If your sample has been collected you can click <strong>Next</strong> to proceed
                            </p>

                            <div style={{margin: "24px 0"}}>

                                <Popconfirm
                                    title="Your Provider will collect your sample"
                                    description="Are you sure?"
                                    onConfirm={next}
                                    onCancel={cancel}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button loading={isLoading} type="primary">
                                        Next
                                    </Button>
                                </Popconfirm>
                            </div>
                        </Col>

                        : isSelfPhlebotomy === "false"

                        &&

                        <Col>
                            <p>
                                To begin the process for mobile phlebotomy, please click&nbsp;&nbsp;

                                <Button
                                    size={'small'}
                                    color={'primary'}
                                    type={'link'}
                                    target={'_blank'}
                                    href="https://travalab.com/e-functional"
                                >
                                    THIS LINK
                                </Button>
                            </p>

                            <div style={{margin: "24px 0"}}>
                                <Button loading={isLoading} type="primary" onClick={next} disabled={true}>
                                    Next
                                </Button>
                            </div>
                        </Col>
                    }

                </Row>


            </Col>
        </Row>


    const OtherTestThanMN = () =>

        <Row>
            <Col lg={24}>

                <p>You are about to activate your EFunctional {detectedProduct?.name} TEST KIT.</p>
                <p>Please follow the instructions on how to self-collect your sample.</p>


                <div style={{margin: "24px 0"}}>
                    <Button loading={isLoading} type="primary" onClick={next}>
                        Next
                    </Button>
                </div>

            </Col>
        </Row>


    switch (detectedKitInfo.test) {
        case 'MN':
            return <IsMicronutrient/>;
        default:
            return <OtherTestThanMN/>;
    }
}

export default CheckTestCode;