import React, {useState} from "react";
import {Button, Col, InputNumber, Row, Space, Typography} from "antd";
import {CheckOutlined, StopOutlined} from "@ant-design/icons";
import styled from "styled-components";


const CustomBlockRow = styled(Row)`

  & .rounded-circle {
    margin: 10px 15px 10px 15px;
    width: 55px;
    height: 55px;
    background: linear-gradient(to right, #01a9ac, #01dbdf);
    border-radius: 50%;

    text-align: center;
    color: white;
    font-weight: bold;

    & .rounded-circle--money {
      position: absolute;
      top: 30px;
      left: 23px;
    }

    & .ant-typography {
      position: relative;
      top: 2px;
      font-size: 8px;
      color: #e8eee5;
    }
  }

  & .main-button {
    & .when-allowed-button {
      margin: 20px 15px 10px 20px;
      padding: 0 80px;
      //background: red;
    }

    & .when-allowed-button-text {
      position: absolute;
      bottom: 4px;
      right: 90px;
      font-size: 10px;
      //background: gold;
    }

    & .when-not-allowed-button {
      margin: 20px 15px 10px 20px;
      width: 100%;
    }

    & .when-we-edit-allowed-balance {
      padding: 0 15px;
    }

    & .when-we-edit-allowed-balance .ant-input-number {
      margin: 20px 0;
      width: 100%;
    }
  }


  @media (min-width: 481px) {

  }

  @media (min-width: 376px) and (max-width: 480px) {

    & .rounded-circle {
      margin: 20px auto 0 auto;
      width: 70px;
      height: 70px;

      & .rounded-circle--money {
        top: 50px;
        left: 43%;
        font-size: 17px;
      }

      & .ant-typography {
        font-size: 10px;
        top: 10px;
      }
    }

    & .main-button {
      margin: 0;
      padding: 0;

      /* -------------------------------------------- */

      & .when-allowed-button {
        margin: 20px auto 0 auto;
        display: block;
        overflow: hidden;
        //background: #e30e0e;
        padding: 0;
        width: 80%;
      }

      & .when-allowed-button-text {
        color: #000;
        text-align: center;
        //background: #e30e0e;
        position: inherit;
        width: 100%;
      }

      /* -------------------------------------------- */


      /* -------------------------------------------- */

      & .when-not-allowed-button {
        margin: 20px auto;
        width: 80%;
        display: block;
        overflow: hidden;
      }

      /* -------------------------------------------- */
    }

  }

  @media (min-width: 300px) and (max-width: 375px) {

    & .rounded-circle {
      margin: 20px auto 0 auto;
      width: 70px;
      height: 70px;

      & .rounded-circle--money {
        top: 50px;
        left: 41%;
        font-size: 17px;
      }

      & .ant-typography {
        font-size: 10px;
        top: 10px;
      }
    }

    & .main-button {
      margin: 0;
      padding: 0;

      /* -------------------------------------------- */

      & .when-allowed-button {
        margin: 20px auto 0 auto;
        display: block;
        overflow: hidden;
        padding: 0;
        width: 80%;
      }

      & .when-allowed-button-text {
        color: #000;
        text-align: center;
        position: inherit;
        width: 100%;
      }

      /* -------------------------------------------- */


      /* -------------------------------------------- */

      & .when-not-allowed-button {
        margin: 20px auto;
        width: 80%;
        display: block;
        overflow: hidden;
      }

      /* -------------------------------------------- */

    }

  }
`;

const WithCorporateBalance = ({useCorporateBalanceVal, setUseCorporateBalanceVal, employeeData}) => {

    const [mainBalance, setMainBalance] = useState(parseInt(employeeData?.employer_balance));  // We show this value in the round element

    const initVal = useCorporateBalanceVal !== null ? useCorporateBalanceVal : ""; // initial value for corporate balance

    const [isCorporateBalance, setIsCorporateBalance] = useState(false);  // State for corporate balance filled or empty.
    const [inputVal, setInputVal] = useState(initVal);  // Input value for using a corporate balance.
    const [error, setError] = useState(null);


    const checkAndSetCorporateBalance = () => {
        // check if corporate balance is available and set it

        const newVal = parseInt(inputVal);

        // If user try to set 0
        if (newVal === 0) {
            setError("You can't set 0. You can cancel it");
            return
        }

        // If user try to set more than he has
        if (newVal <= parseInt(employeeData?.employer_balance)) {
            setError(null);
            setUseCorporateBalanceVal(newVal);  // set corporate balance value in main component
            setIsCorporateBalance(false);

            // set the main balance after using corporate balance
            setMainBalance(parseInt(employeeData?.employer_balance) - newVal);
        } else {
            setError("You don't have enough");
        }
    }

    const cancelCorporateBalance = () => {
        setUseCorporateBalanceVal(null);
        setIsCorporateBalance(false);
        setInputVal("");

        setMainBalance(parseInt(employeeData?.employer_balance));  // reset the main balance (what we show)
    }

    const renderComponent = () => {

        // If the user already set corporate balance amount and press - confirm button
        if (inputVal && !!!isCorporateBalance) {
            return <>
                <Button
                    className={'when-allowed-button'}
                    type={'dashed'}
                    onClick={() => setIsCorporateBalance(true)}
                >
                    Allowed ${inputVal}
                </Button>

                <Typography.Text className={'when-allowed-button-text'}>
                    Click to change
                </Typography.Text>
            </>
        }

        // If corporate balance is empty, render button for using corporate balance.
        if (!!!isCorporateBalance) {
            return <>
                <Button
                    className={'when-not-allowed-button'}
                    type={'dashed'}
                    onClick={() => setIsCorporateBalance(true)}
                >
                    Use corporate balance
                </Button>
            </>
        }

        // If corporate balance is not empty, render input for using corporate balance.
        if (!!isCorporateBalance) {
            return <>
                <Space size={'small'} className={'when-we-edit-allowed-balance'}>
                    <InputNumber
                        status={error && "error"}
                        value={inputVal}
                        min={0}
                        onChange={setInputVal}
                        placeholder={'Enter amount'}
                        onPressEnter={checkAndSetCorporateBalance}
                    />
                    {error && (
                        <Typography.Text
                            type="danger"
                            style={{position: 'absolute', bottom: 4, right: 85, fontSize: 10}}
                        >
                            {error}
                        </Typography.Text>
                    )}

                    <Button
                        type={'primary'}
                        onClick={checkAndSetCorporateBalance}
                    >
                        <CheckOutlined/>
                    </Button>

                    <Button
                        danger
                        onClick={cancelCorporateBalance}
                        type={'dashed'}
                    >
                        <StopOutlined/>
                    </Button>
                </Space>
            </>
        }
    }

    return <CustomBlockRow style={{border: "2px dashed #169F8A", margin: "20px 0"}}>
        <Col xs={24} sm={5}>
            <div className={"rounded-circle"}>
                <span className={"rounded-circle--money"}>
                    $ {mainBalance}
                </span>

                <Typography.Text>
                    Balance
                </Typography.Text>
            </div>
        </Col>

        <Col xs={24} sm={16} className={"main-button"}>
            {renderComponent()}
        </Col>
    </CustomBlockRow>
}


export default WithCorporateBalance;