import restAPI from "../../../features/restAPI";
import React, {useState, useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import {Button, Modal, Upload, App, Divider} from 'antd';
import {UploadOutlined} from "@ant-design/icons";
import {TaskStatusProvider, useTaskStatus} from '../../tasks/TaskStatusContext';

const EmployerEmployeesDownloaderModal = ({authContext, setEmployeesList}) => {
    const {message} = App.useApp();
    const {access, employerData} = authContext;
    const formData = new FormData();
    let navigate = useNavigate();
    const [open, setOpen] = useState(false);

    const [fileCSV, setFileCSV] = useState(null);
    const [myFileList, setMyFileList] = useState(null);
    const [uploading, setUploading] = useState(false);

    // Long task
    // const [taskID, setTaskID] = useState(null);

    const {taskID, setTaskID} = useTaskStatus();

    const authConfig = {
        headers: {
            Authorization: `ECL ${access}`,
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
        }
    }


    const showModal = () => {
        setOpen(true);
    };

    const handleCancel = () => {
        setOpen(false);
    };

    const handleUpload = async () => {


        try {
            setUploading(true);

            const response = await restAPI.post('/upload-employees/', formData, authConfig);

            handleCancel();
            console.log(response?.data);

            // todo check results
            setTaskID(response?.data?.task_id);

            message.loading(`Your task accepted! Loading in progress ...`, 1);

        } catch (error) {
            console.log(error);
            if (error?.response?.status === 401) {
                navigate('/session-expired');
            } else {
                console.log("EmployerTestSettingsModal Error", error);
            }

            handleCancel();
            message.error('Employees file upload failed.');
        } finally {
            setFileCSV(null);
            setMyFileList(null);
            setUploading(false);
        }
    };

    const props = {
        onRemove: (file, fileList) => {
            setFileCSV(null);
            setMyFileList(null);
            return false;
        },
        beforeUpload: (file, fileList) => {
            if (file.type === "text/csv") {
                setFileCSV(file);
                setMyFileList(fileList);
            } else {
                message.error('We accept only .csv files');
            }
            return false;
        },
    };

    useEffect(() => {
        if (fileCSV) {
            // This fields will be sending to API
            formData.append('employees_file', fileCSV);
            formData.append('employer_id', employerData?.id);
        }
        // eslint-disable-next-line
    }, [fileCSV, employerData?.id])

    return <>

        <Button size={'small'} onClick={showModal}>
            <UploadOutlined/>&nbsp;Upload employees
        </Button>

        <Divider/>

        <Modal
            footer={null}
            width={window.innerWidth > 1200 ? "40%" : '100%'}
            title={`Upload employees for ${employerData?.name}`}
            open={open}
            onCancel={handleCancel}
        >
            <p>
                We can loading only employees with unique names
            </p>

            <Upload {...props} maxCount={1} fileList={myFileList} accept={'.csv'}>
                <Button icon={<UploadOutlined/>}>Select File</Button>
            </Upload>

            <Button
                name={'employees_file'}
                type="primary"
                onClick={handleUpload}
                disabled={!fileCSV}
                loading={uploading}
                style={{
                    marginTop: 16,
                }}
            >
                {uploading ? 'Uploading' : 'Start Upload'}
            </Button>


        </Modal>
    </>;
}

export default EmployerEmployeesDownloaderModal;