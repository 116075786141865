import React, {useEffect, useState} from 'react';
import restAPI from "../../../features/restAPI";
import {LoadingOutlined} from "@ant-design/icons";
import {App} from "antd";

import {useTaskStatus} from "./index";

const POLL_INTERVAL = 3000; // Poll every 5 seconds

const TaskStatus = () => {
    const [taskStatus, setTaskStatus] = useState(null);
    const {taskID, setTaskID} = useTaskStatus();  // *** Get values from global app contex

    const {message} = App.useApp();

    const checkStatus = async () => {
        try {
            const response = await restAPI.get(`/check-task-status/${taskID}/`);
            const data = response.data;


            if (data.status === 'ready' || data.status === 'FAILURE') {
                // If the task is finished or failed, clear the interval

                setTaskStatus(data.status);

                if (response?.data?.result?.hasOwnProperty('error')) {
                    message.success("No unique employees from csv-file", 3);
                    return true;
                }


                console.log(response);

                if (response.data.total_count === 0) {
                    message.success("No unique employees from csv-file", 3);
                    return true;
                }

                const messageContent = `We load ${response?.data?.result?.total_count} unique employees from csv-file`;
                message.success(messageContent, 3);


                setTaskID(null); // clear `taskID` after successfully results

                return true;
            }
            setTaskStatus(data.status);

        } catch (error) {
            console.error('Error fetching task status:', error);
        }

        return false;
    };

    useEffect(() => {

        if (taskID) {

            // Immediately check the task status
            checkStatus();

            // Start polling the server for updates
            const pollingInterval = setInterval(async () => {
                const finished = await checkStatus();
                if (finished) {
                    clearInterval(pollingInterval);
                }
            }, POLL_INTERVAL);

            // Clean up the interval when the component unmounts
            return () => {
                clearInterval(pollingInterval);
            };
        }

    }, [taskID]);

    if (!taskID) return null;

    return <>{taskStatus === 'pending' ? <p><LoadingOutlined/></p> : null}</>;
};

export default TaskStatus;
