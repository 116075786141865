import React, {createContext, useContext, useState} from 'react';

const TaskStatusContext = createContext();

export const useTaskStatus = () => {
    return useContext(TaskStatusContext);
};

export const TaskStatusProvider = ({children}) => {
    /* Task controller. Listener component placed in <MainLayout/> and named <TaskStatus> */
    const [taskID, setTaskID] = useState(null);

    const value = {
        taskID, setTaskID,
    };

    return <TaskStatusContext.Provider value={value}>{children}</TaskStatusContext.Provider>;
};
