import React, {useEffect, useContext} from "react";
import {useNavigate} from "react-router-dom";
import useLocalStorage from "../../../features/hooks/useLocalStorage";
import {AuthContext} from '../AuthContext';


const getAuthContext = () => {
    /* This is a function what getting a current localstorage object by key - authContext.
    Result this function is an init state for AuthContex */

    const authContextString = localStorage.getItem('authEmployeeContext');
    return JSON.parse(authContextString) || null;
}

const AuthRequired = ({children}) => {
    /* Custom Auth controller component  */

    const navigate = useNavigate();
    const {authContext} = useContext(AuthContext);

    useEffect(() => {
        if (authContext === null) {
            navigate('/login');
        }
    }, [authContext]);

    return children;
}

const AuthProvider = ({children}) => {
    // const [isAuth, setIsAuth] = useLocalStorage('authEmployeeContext', getAuthContext()); // get default value from LocalStorage

    // const [authContext, setAuthContext] = useState(isAuth);

    const [authContext, setAuthContext] = useLocalStorage('authEmployeeContext', getAuthContext());

    const login = async (tokens, employeePatientData) => {
        /*
        This function waiting from Login component two params:
        @tokens object
        @patientData object   */

        // setIsAuth({...tokens, employeePatientData});  // save to localstorage
        setAuthContext({...tokens, employeePatientData}); // save tokens and parse user_id
    }

    const authConfig = {
        /* headers object for all secure requests. `ECL` it's not standard, but it's custom token for security. */

        headers: {
            Authorization: `ECL ${authContext?.access}`,
        },
    };

    const updateEmployeePatientData = newStateData => {

        setAuthContext(prevState => {
            const {employeePatientData} = prevState;
            return {...prevState, employeePatientData: {...employeePatientData, ...newStateData}};
        });

    }

    // const updateShippingInfo = newShippingData => {
    //     setAuthContext(prevState => {
    //         const {employeePatientData} = prevState;
    //         const {shipping_addresses} = employeePatientData;
    //         const filteredShippingInfos = shipping_addresses.filter(address => address.default !== true);  // at this moment I'm creating only for `default` address
    //
    //         return {
    //             ...prevState, employeePatientData: {
    //                 ...employeePatientData, shipping_addresses: [{...newShippingData}, ...filteredShippingInfos]
    //             }
    //         };
    //     });
    // }

    const logout = () => {
        /* Logout function. Clear all localstorage keys and turn off AuthContex */
        setAuthContext(null); // change react context
        // setIsAuth(null); // change localstorage
    }

    return <AuthContext.Provider
        value={
            {
                authContext, authConfig,
                login, logout,
                updateEmployeePatientData
            }
        }>
        {children}
    </AuthContext.Provider>
}


export {AuthProvider, AuthRequired}