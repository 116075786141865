import dayjs from "dayjs";
import React, {useContext, useState, useEffect} from "react";
import MainPageTitle from "../../../components/MainPageTitle";


import {AuthContext} from "../../auth/AuthContext";
import restAPI from "../../../features/restAPI";
import {authPages} from "../../../routing/config";

import {Col, List, Row, Skeleton, App, Spin} from "antd";
import {CalendarOutlined, LoadingOutlined, ProfileOutlined} from "@ant-design/icons";

import EmployerEmployeesDownloaderModal from "./EmployerEmployeesDownloaderModal";


const EmployeesManagementEmails = () => {
    const {
        employees
    } = authPages;

    const {message} = App.useApp();

    const [isLoading, setIsLoading] = useState(true);
    const {authContext} = useContext(AuthContext); // auth data from current session

    const [employeesList, setEmployeesList] = useState([]);


    const authConfig = {
        headers: {
            Authorization: `ECL ${authContext?.access}`,
        },
    };

    const loadAllEmployees = async () => {

        try {
            const apiUrl = `/employer-employees/?client=${authContext?.employerData?.id}`;
            const response = await restAPI.get(apiUrl, authConfig);

            setEmployeesList(response?.data?.results);
            setIsLoading(false);

        } catch (error) {
            console.log(error);

            if (error.code === "ERR_BAD_REQUEST" && error?.response?.status === 401) {
                message.error('Your session has expired. Please, login again.');
                authContext?.setAuthContext(null);
            }
        }
    };

    const [productsList, setProductsList] = useState([]);

    const loadProductList = async () => {
        setIsLoading(true);

        await restAPI.get('/products/', authConfig)
            .then(response => {

                const customProductsForFilteringComponent = response?.data?.results.map(
                    product => ({
                        ...product,
                        label: product?.name,
                        value: product?.name,
                        key: product?.id,
                    }));

                const addShowAll = [{
                    key: 1000,
                    label: 'Show all',
                    value: 'all',
                    code: 'all'
                }, ...customProductsForFilteringComponent];

                setProductsList(addShowAll);

            }).catch(err => {
                console.log(err);
                setProductsList([]);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    useEffect(() => {

        (async () => {
            await loadAllEmployees();
        })();
        // eslint-disable-next-line
    }, []);

    // const [options, setOptions] = useState([]);


    const [open, setOpen] = useState(false);
    const [childrenDrawer, setChildrenDrawer] = useState(false);
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };
    const showChildrenDrawer = () => {
        setChildrenDrawer(true);
    };
    const onChildrenDrawerClose = () => {
        setChildrenDrawer(false);
    };

    const onClick = ({key}) => {
        message.info(`Click on item ${key}`);
    };

    return <Spin tip="Loading..." spinning={isLoading} indicator={<LoadingOutlined size={'large'}/>}>
        <Row style={{padding: "0 10px"}}>

            <Col xs={24} lg={24} xl={24}>
                <MainPageTitle>{employees.title}</MainPageTitle>
            </Col>

            <Col xs={24} lg={24}>

                <EmployerEmployeesDownloaderModal authContext={authContext} setEmployeesList={setEmployeesList}/>

                {/*<Divider/>*/}

                <List
                    loading={isLoading}
                    itemLayout="horizontal"
                    dataSource={employeesList}
                    renderItem={(item) => (
                        <List.Item
                            key={item?.id}
                            actions={[
                                <p><CalendarOutlined/> {dayjs(item?.created).format('YYYY-MM-DD')}</p>,
                            ]}
                        >
                            <Skeleton avatar title={false} loading={isLoading} active>
                                <List.Item.Meta
                                    avatar={<ProfileOutlined style={{fontSize: 40}}/>}
                                    title={<span>{item?.first_name}</span>}
                                    description={`Status: ${item?.active ? "Active" : "Disabled"}`}
                                />
                                <div>

                                </div>
                            </Skeleton>
                        </List.Item>
                    )}
                />

            </Col>
        </Row>
    </Spin>
}

export default EmployeesManagementEmails;