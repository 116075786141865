import React, {useContext, useEffect, useState} from "react";
import {authPages} from "../../../routing/config";
import styled from "styled-components";
import restAPI from "../../../features/restAPI";

import MainPageTitle from "../../../components/MainPageTitle";
import {AuthContext} from "../../auth/AuthContext";
import productImg from '../../../assets/images/product-box.png';

import {DeleteOutlined, LoadingOutlined, ShoppingCartOutlined} from "@ant-design/icons";
import {Button, Card, Col, Divider, message, Row, Skeleton, Space, Spin, Tag, Tooltip, Typography} from "antd";

import WithCorporateBalance from "./WithCorporateBalance";
import PaymentForm from "./PaymentForm";

const {Meta} = Card;

const ShopCard = styled(Card)`
  //opacity: .6;
  transition: 0.5s;
  overflow: hidden;
  width: 100%;
  border: none;

  &:hover {
    opacity: 1;
    cursor: pointer;
    transition: 0.5s;
    box-shadow: aliceblue;
  }

  & * {
    font-family: Montserrat, sans-serif;
    outline-color: #01a9ac;
  }

  & .ant-card-head-title {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 1px;
  }

  & .shopping-button {
    background: linear-gradient(to right, #01a9ac, #01dbdf);
  }

  & .ant-card-cover {
    width: 80%;
    margin: 0 auto;
    transition: 0.1s;
  }
`


const OrderCardCol = styled(Col)`
  @media (max-width: 767px) {
    margin: 25px 0 50px 0;
  }
`;

const CustomOrderCard = styled(Card)`
  //@media (min-width: 300px) and (max-width: 480px) {
  //  background: #c2bebe;
  //}
`;


const NewOrder = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const success = message => {
        messageApi.open({
            type: 'success', content: `${message}`, duration: 3
        });
    };

    const [shoppingCart, setShoppingCart] = useState([]);

    const {newOrder} = authPages;
    const {authContext, authConfig, updateEmployeePatientData} = useContext(AuthContext); // auth data from current session
    const {employeePatientData} = authContext;

    const [isPaymentForm, setIsPaymentForm] = useState(false);

    const [isLoading, setIsLoading] = useState(true);

    const [productsList, setProductsList] = useState([]);
    const [discountPercent, setDiscountPercent] = useState(null); //for each product in contract from API

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isClicked, setIsClicked] = useState(false);

    const [employeeData, setEmployeeData] = useState(null);  // employee actual data from API

    const [isPaymentSuccessful, setIsPaymentSuccessful] = useState(null);  // default value is null
    const [transactionError, setTransactionError] = useState(null);  // default value is null


    const showPaymentForm = () => {
        setIsPaymentForm(true);
    }

    const loadProductsByEmployerContract = async () => {
        try {
            setIsLoading(true);

            // todo - get Corporate products from API with unique prices
            const contractResponse = await restAPI.get(`/contracts/?client_id=${authContext?.employeePatientData?.client}`, authConfig);

            setDiscountPercent(contractResponse.data.results[0].discount);

            const products = contractResponse.data.results[0].products;

            const productIds = products.join(',');
            const getConfig = {
                headers: authConfig.headers,
                params: {
                    ids: productIds
                }
            }

            const response = await restAPI.get(`/products/`, getConfig);

            setProductsList([...response?.data?.results]);

        } catch
            (error) {

            console.log(error);

        } finally {
            setIsLoading(false);
        }
    }

    const getEmployeeActualData = async () => {
        try {
            setIsLoading(true);
            const response = await restAPI.get(`/employer-employees/${authContext?.employeePatientData?.id}/`, authConfig);

            // Update component state
            setEmployeeData(response?.data);

            // Update Local Storage value
            updateEmployeePatientData({
                employer_balance: parseInt(response?.data?.employer_balance)
            });
        } catch (error) {

            console.log(error);

        } finally {
            setIsLoading(false);
        }
    }


    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);

                await Promise.all([
                    loadProductsByEmployerContract(),
                    getEmployeeActualData()
                ]);
            } finally {
                setIsLoading(false);
            }
        })();
        // eslint-disable-next-line
    }, []);

    const addToCart = product => {
        const exist = shoppingCart.find((x) => x.id === product.id);

        if (exist && exist.qty < 5) {
            setShoppingCart(shoppingCart.map(x => x.id === exist.id ? {...exist, qty: exist.qty + 1} : x));
        }

        if (!exist) {
            setShoppingCart([...shoppingCart, {...product, qty: 1}]);
        }
    }

    const removeFromCart = product => {
        const exist = shoppingCart.find((x) => x.id === product.id);
        if (exist.qty === 1) {
            setShoppingCart(shoppingCart.filter((x) => x.id !== product.id));
        } else {
            setShoppingCart(shoppingCart.map((x) => x.id === product.id ? {...exist, qty: exist.qty - 1} : x));
        }
    }

    // ------------------ Corporate Balance ------------------
    const [useCorporateBalanceVal, setUseCorporateBalanceVal] = useState(null);  // How much $ from corporate balance using it for current order

    const resetCart = () => {
        setShoppingCart([]);
        setUseCorporateBalanceVal(null);
    }
    // ------------------ Corporate Balance ------------------

    const sendCorporateOrderToAPI = async (values) => {
        /*
        * todo - I need check all all data here before send to API
        *  - but i don't need send all data to API, only needed data for Corporate Order
        *  - need check billing info and shipping info if Order amount >>> 0 and request to add card information
        * */

        // check shopping cart, if - 0 -> stop
        if (shoppingCart.length === 0) {
            return false;
        }

        try {
            setIsClicked(true);
            setIsLoading(true);

            const cart = shoppingCart.map(p => ({product: parseInt(p.id), quantity: p.qty}));

            const orderData = {
                cart: [...cart],
                allowedBalance: useCorporateBalanceVal,
                employeeID: parseInt(employeePatientData?.id),

                // This data in variable - `values` - comes from <PaymentForm/>  --------------- //
                cardNumber: values?.credit_card_number || null,
                cardCVC: values?.credit_card_cvv || null,
                cardExpMonth: values?.credit_card_exipation_month || null,
                cardExpYear: values?.credit_card_exipation_year || null,
                cardHolderName: values?.credit_card_holder_name || null,
                cardHolderEmail: values?.billing_email || null,

                billingAddress: values?.billing_address || null,
                billingCity: values?.billing_city || null,
                billingState: values?.billing_state || null,
                billingZip: values?.billing_zip_code || null,
                billingCountry: "US"
                // This data in variable - `values` - comes from <PaymentForm/>  --------------- //
            };

            // console.log(orderData);
            //
            // return false;

            const response = await restAPI.post(`/orders/save-corporate-order/`, orderData, authConfig);

            console.log(response);

            // ⚠️ Update component state for UI
            setEmployeeData({
                ...employeeData,
                employer_balance: parseInt(employeeData?.employer_balance) - useCorporateBalanceVal
            });

            // ⚠️ Update Local Storage value
            updateEmployeePatientData({
                employer_balance: parseInt(employeeData?.employer_balance) - useCorporateBalanceVal
            });

            success(`Your order was successfully generated. Please see your "History" tab for more information. Order #${response?.data?.order.id}`);
            resetCart();
            setIsSubmitted(true);
            setIsClicked(false);


            if (orderData.cardNumber !== null) setIsPaymentSuccessful(true); // ⚠️ Update state for <PaymentForm/> component

        } catch (error) {

            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);

                if (error.response.status === 400) {
                    // Process and handle 400 status code...
                    console.log(error.response.data.detail);  // assuming 'detail' contains the error message
                    setTransactionError(error.response.data.detail);
                    setIsPaymentSuccessful(false); // ⚠️ Update state for <PaymentForm/> component
                }

            } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }

        } finally {
            setIsLoading(false);
            setIsClicked(false);
        }

    }

    const calculateDiscountedPrice = (product, discountPercent) => {
        return Math.ceil(product.price - (product.price * (discountPercent / 100)));
    }

    const totalPrice = shoppingCart.reduce((a, c) => a + c.qty * calculateDiscountedPrice(c, discountPercent), 0);

    const calculateTotalPrice = () => {
        return totalPrice - useCorporateBalanceVal;
    };

    const isPaymentRequired = calculateTotalPrice() > 0;

    const handleOnClick = isPaymentRequired ? showPaymentForm : sendCorporateOrderToAPI;

    const renderPrice = () => {
        if (totalPrice === useCorporateBalanceVal) {
            return <>{'$ ' + useCorporateBalanceVal}</>;
        } else if (totalPrice <= useCorporateBalanceVal) {
            return <>{'$ ' + totalPrice}</>;
        } else {
            return <>{'$ ' + useCorporateBalanceVal}</>;
        }
    };

    const discountedPrice = calculateTotalPrice() < 0 ? 0 : calculateTotalPrice();

    const DiscountLabel = ({discountPercent}) => (
        <Tag style={{position: 'absolute', bottom: '10px', left: '10px'}}>
            Discount {discountPercent}%
        </Tag>
    );

    return <Spin tip="Loading..." spinning={isLoading || isClicked} indicator={<LoadingOutlined size={'large'}/>}>

        {contextHolder}

        <Row gutter={[24, 0]}>
            <Col xs={24} lg={24} xl={24}>
                <MainPageTitle>{newOrder.title}</MainPageTitle>
            </Col>


            <Col xs={24} lg={24} xl={12} xxl={16}>


                <Row gutter={[24, 24]}>

                    {productsList.map(product =>

                        <Col xs={24} md={12} lg={12} xl={12} xxl={8} key={product.id}>

                            <ShopCard
                                title={product.name}
                                hoverable={false}
                                bordered={false}
                                cover={<img src={productImg} alt={product.name}/>}
                                actions={[<>
                                    <Space>
                                        {shoppingCart?.find(x => x.id === product.id && x.qty >= 1)
                                            ? <Space size={'large'}>

                                                <Button onClick={() => removeFromCart(product)} type="primary">
                                                    -
                                                </Button>

                                                <span>{shoppingCart?.find(x => (x.id === product.id))?.qty}</span>

                                                <Button type="primary" onClick={() => addToCart(product)}>+</Button>
                                            </Space>

                                            : <Button className={'shopping-button'} type="primary"
                                                      icon={<ShoppingCartOutlined/>}
                                                      onClick={() => addToCart(product)}
                                            >
                                                Add to cart
                                            </Button>}

                                        <Typography.Text>
                                            {/*<strong>$ {Math.ceil(product.price - (product.price * (discountPercent / 100)))}</strong>*/}
                                            <strong>$ {calculateDiscountedPrice(product, discountPercent)}</strong>
                                        </Typography.Text>
                                    </Space>
                                </>,]}
                            >
                                {discountPercent !== null && discountPercent > 0
                                    ? <Meta
                                        // title={<Tag>Discount {discountPercent}%</Tag>}
                                        description={<Tooltip title={`Discount ${discountPercent}%`}>
                                            <Tag style={{position: 'absolute', right: '10px', fontSize: 9}}>
                                                Discount {discountPercent}%</Tag>
                                        </Tooltip>}
                                    />
                                    : null
                                }
                            </ShopCard>

                        </Col>)}
                </Row>
            </Col>

            <OrderCardCol xs={24} sm={24} md={24} lg={24} xl={12} xxl={8}>
                <CustomOrderCard
                    width={'100%'}
                    title={"Your order"}
                    hoverable={true}
                    bordered={true}
                    actions={[<>
                        <Space size={'large'}>

                            <Button
                                hidden={shoppingCart?.length === 0}
                                className={'shopping-button'}
                                icon={<DeleteOutlined/>}
                                onClick={resetCart}
                            >
                                Reset cart
                            </Button>

                            <Button
                                // Check - and choose method. If - 0 -> send order to API, else - show the payment form
                                // onClick={(shoppingCart.reduce((a, c) => a + c.qty * c.price, 0) - useCorporateBalanceVal) <= 0
                                //     ? sendCorporateOrderToAPI
                                //     : showPaymentForm}
                                onClick={handleOnClick}
                                hidden={shoppingCart?.length === 0}
                                disabled={shoppingCart.length === 0}
                                type={'primary'}
                            >
                                Order now
                            </Button>
                        </Space>
                    </>,]}
                >
                    <Skeleton loading={isLoading} active>
                        <Meta
                            title={null}
                            description={<>
                                {shoppingCart.length !== 0

                                    ? shoppingCart.map(p =>
                                        <Row key={p.id}>
                                            <Col xs={15}>
                                                {p.name}
                                            </Col>

                                            <Col xs={6}>
                                                $ {calculateDiscountedPrice(p, discountPercent)}
                                            </Col>

                                            <Col xs={3}>
                                                {p.qty}
                                            </Col>
                                        </Row>)

                                    : <Row>
                                        <Col>
                                            Cart is empty. <br/>
                                            Please select your desired test(s) and
                                            add them to your cart to place an order
                                        </Col>
                                    </Row>}


                                {shoppingCart.length !== 0 && <>

                                    <WithCorporateBalance
                                        employeeData={employeeData}
                                        useCorporateBalanceVal={useCorporateBalanceVal}
                                        setUseCorporateBalanceVal={setUseCorporateBalanceVal}
                                    />

                                    <Row>
                                        {useCorporateBalanceVal !== null && <>
                                            <Col span={24}>
                                                <strong>Full cost:&nbsp;</strong>
                                                $ {shoppingCart.reduce((a, c) => a + c.qty * c.price, 0)}
                                            </Col>

                                            <Col span={24}>
                                                <strong>Applied the discount:&nbsp;</strong>
                                                {renderPrice()}
                                            </Col>
                                        </>}

                                        <Divider/>


                                        <Col span={24}>
                                            <strong>Your responsibility:&nbsp;</strong>
                                            {/*$ {(shoppingCart.reduce((a, c) => a + c.qty * c.price, 0) - useCorporateBalanceVal) < 0 ? 0 : (shoppingCart.reduce((a, c) => a + c.qty * c.price, 0) - useCorporateBalanceVal)}*/}
                                            $ {discountedPrice}
                                        </Col>
                                    </Row>
                                </>}


                            </>}
                        />
                    </Skeleton>
                </CustomOrderCard>
            </OrderCardCol>
        </Row>

        <PaymentForm
            isLoading={isLoading}
            isPaymentSuccessful={isPaymentSuccessful}
            setIsPaymentSuccessful={setIsPaymentSuccessful}
            turnOn={isPaymentForm}
            setIsPaymentForm={setIsPaymentForm}
            sendOrderData={sendCorporateOrderToAPI}
            transactionError={transactionError}
        />

    </Spin>
}

export default NewOrder;