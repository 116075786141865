import React, {useState, useEffect, useContext} from "react";
import restAPI from "../../../features/restAPI";
import {LoadingOutlined} from "@ant-design/icons";
import {Col, Row, Input, Form, Button, message, Switch, Spin, Select} from 'antd';
import {AuthContext} from "../../auth/AuthContext";

import statesJSON from './states.json';


const statesArray = JSON.parse(statesJSON).map(s => ({value: s.postal, label: s.name}));

const ShippingInformation = () => {

    const [form] = Form.useForm();
    const [submitErrors, setSubmitErrors] = useState(null);
    const [formState, setFormState] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [patientData, setPatientData] = useState(null);  // All data about patient account

    const {
        authContext,
        authConfig,
    } = useContext(AuthContext); // auth data from current session

    const {
        employeePatientData, // Employee data from LS
    } = authContext;


    const getPatientData = async () => {

        try {
            const response = await restAPI.get(`/patients/all/?user_id=${employeePatientData?.auth_user}`, authConfig);

            setPatientData(response.data.results[0]);

            form.setFieldsValue({
                ...response.data.results[0].shipping_addresses[0]
            });

        } catch (error) {
            if (error.hasOwnProperty('response')) {
            } else {
                console.error(error);
            }
        }
    }

    const submitForm = async (formData) => {

        try {

            const endpoint = "/patients/shipping-addresses/";
            const {id} = patientData; // patient id

            const addressesCount = await restAPI.get(`${endpoint}?patient=${id}`, authConfig); // get addresses by id

            // if we have address or addresses -> find `default` and update it
            if (addressesCount?.data.count >= 1) {

                const shippingAddress = patientData?.shipping_addresses.find(s => s.default === true);
                const response = await restAPI.patch(`/patients/shipping-addresses/${shippingAddress.id}/`, formData, authConfig);

                setPatientData(prevState => ({
                    ...prevState,
                    shipping_addresses: response.data
                }));

            } else {
                throw new Error("Error. Unpredictable application behavior");
            }

        } catch (error) {

            console.log(error);

            let errors = '';

            for (const [key, value] of Object.entries(error?.response?.data)) {

                console.log(value[0]);

                errors += `${key} - ${value[0]}`;
            }


            setSubmitErrors(errors); // TODO finish it

            throw new Error(errors)


        } finally {
            setIsLoading(false);
        }
    };

    const onFinish = async (values) => {
        /* Submit valid form */

        try {
            setIsLoading(true);

            await submitForm(values);

            setFormState(prevState => !prevState);

            message.success({
                content: `Shipping information updated!`, duration: 3,
            });

        } catch (error) {
            console.log(error);

            message.error({
                content: 'We have an errors!', duration: 2,
            });

        } finally {
            setIsLoading(false);
        }
    }

    const triggerSubmitHandler = () => form.submit();

    const onChange = value => {
        setFormState(value);
    }

    useEffect(() => {
        if (patientData === null) {
            try {
                setIsLoading(true);
                (async () => {
                    await getPatientData();
                })();
            } finally {
                setIsLoading(false);
            }
        }
        // eslint-disable-next-line
    }, [patientData]);


    return <Spin tip="Loading..." spinning={isLoading} indicator={<LoadingOutlined size={'large'}/>}>

        <Row style={{padding: "0 10px"}}>
            <Col>
                <Form>
                    <Form.Item
                        label={'Edit mode: '}
                        name="shippingInfoMode"
                    >
                        <Switch checked={formState} onChange={onChange}/>
                    </Form.Item>
                </Form>
            </Col>

            <Col xs={24} lg={24} xl={24}>

                <Form
                    disabled={!formState}
                    layout="vertical"
                    size={'middle'}
                    onFinish={onFinish}
                    form={form}
                    autoComplete="off"
                >

                    <Row gutter={[24, 24]}>

                        <Col xs={24} lg={12}>
                            <Form.Item
                                label={'Address 1'}
                                name="address_1"
                                rules={[{
                                    required: true, message: 'Please input address 1!',
                                }]}
                            >
                                <Input placeholder="Please input address 1"/>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={12}>
                            <Form.Item
                                label={'Address 2'}
                                name="address_2"
                            >
                                <Input placeholder="Address 2"/>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={8}>
                            <Form.Item
                                label={'City'}
                                name="city"
                                rules={[{
                                    required: true, message: 'Please input your city!',
                                }]}
                            >
                                <Input placeholder="City"/>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={8}>
                            <Form.Item
                                label={'State'}
                                name="state"
                                rules={[{required: true, message: 'Please input state!'}]}
                            >
                                <Select options={statesArray}/>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={8}>
                            <Form.Item
                                label={'Post code'}
                                name="postcode"
                                rules={[
                                    {required: true, message: 'Please input post code!'},
                                    {required: true, min: 5, max: 5, message: 'Postal code length must be equal 5'}
                                ]}
                            >
                                <Input placeholder="12345"/>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={24}>
                            <Form.Item
                                label={'Shipping notes'}
                                name="shipping_notes"
                            >
                                <Input.TextArea placeholder="More shipping details if needed"/>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Button
                                hidden={!formState}
                                style={{marginBottom: 50,}}
                                type="primary"
                                loading={false}
                                onClick={triggerSubmitHandler}
                            >
                                Update information
                            </Button>
                        </Col>

                    </Row>
                </Form>
            </Col>
        </Row>
    </Spin>
}

export default ShippingInformation;