import React, {useState, useEffect} from "react";
import {CreditCardOutlined, CompassOutlined, UserOutlined, MailOutlined} from "@ant-design/icons";
import {Modal, Col, Form, Row, Input, Select, Divider, Typography, Spin, Result} from "antd";
import styled from "styled-components";

import StatesJSON from "../../application/MyInformation/states.json";

const parsedStates = JSON.parse(StatesJSON);

const AuthorizeNetPaymentForm = styled(Form)`
  padding: 0 20px;
  background: #fff;
  border: 1px dashed #c5c0c0;

  & * {
    font-family: "Montserrat", sans-serif;
  }

  .ant-form-item-explain-error {
    font-size: 12px !important; /* re-write base styles for ant error */
  }
`;

const getYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const yearOptions = [];

    for (let i = currentYear; i <= currentYear + 15; i++) {
        yearOptions.push(
            <Select.Option key={i} value={i.toString()}>
                {i}
            </Select.Option>
        );
    }

    return yearOptions;
}

const cardNumberMask = value => {
    let x = value.replace(/\D/g, "").match(/(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})/);
    return (value = !x[2] ? x[1] : `${x[1]} ${x[2]}${x[3] ? " " + x[3] : ""}${x[4] ? " " + x[4] : ""}`);
};

const PaymentForm = (props) => {
    const {
        turnOn,
        setIsPaymentForm,
        sendOrderData,
        isPaymentSuccessful,
        setIsPaymentSuccessful,
        isLoading,
        transactionError
    } = props;

    const [form] = Form.useForm();
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [isPaymentLoading, setIsPaymentLoading] = useState(false);

    const handleOk = async () => {

        // The Transaction is successful, we need to close the modal
        if (isPaymentSuccessful === true) {
            setIsPaymentForm(false);
            setIsPaymentSuccessful(null); // reset payment status
            return;
        }

        // Payment form isn't built yet
        if (isPaymentSuccessful === null) {
            try {

                setIsPaymentLoading(true);
                await form
                    .validateFields()
                    .catch((error) => {
                        console.error("Validation failed:", error);
                    });

                await form.submit();
            } catch (error) {
                console.error("Error:", error);
            } finally {
                setIsPaymentLoading(false);
            }
        }

        // Transaction failed
        if (isPaymentSuccessful === false) {
            setIsPaymentForm(false);
            setIsPaymentSuccessful(null); // reset payment status
        }
    };

    const handleCancel = () => {
        // console.log('Clicked cancel button');
        setIsPaymentForm(false);
        setIsPaymentSuccessful(null); // reset payment status
    };

    const onCancel = () => {
        if (!isPaymentLoading) {
            handleCancel();
        }
    }

    // fix bug on mobile devices with scroll
    if (turnOn) {
        document.body.style.overflow = "hidden";
    } else {
        document.body.style.overflow = "auto";
    }


    useEffect(() => {

        // If payment is successful, we need to close the modal
        if (isPaymentSuccessful === true) {
            setIsPaymentLoading(false);
            setConfirmLoading(false);
        }

        // todo - re-check it later
        // // If payment is failed - we need to show the error message
        // if (isPaymentSuccessful === false) {
        //     // setIsPaymentLoading(false);
        //     // setConfirmLoading(false);
        //     // setIsPaymentForm(false);
        // }

    }, [isPaymentSuccessful]);

    return (

        <Modal
            closable={!isPaymentLoading}
            open={turnOn}
            onOk={handleOk}
            confirmLoading={confirmLoading}
            onCancel={onCancel}
        >
            <AuthorizeNetPaymentForm
                form={form}
                onFinish={sendOrderData}
                layout="vertical"
            >

                {isPaymentSuccessful === null

                    ? <Spin spinning={isLoading}>
                        <Row>
                            <Divider dashed>
                                <Typography.Title level={5}>
                                    <CreditCardOutlined/>&nbsp;Credit Card Information
                                </Typography.Title>
                            </Divider>

                            <Col xs={24} lg={24}>
                                <Form.Item
                                    label="Card Holder Name"
                                    name="credit_card_holder_name"
                                    rules={[
                                        {required: true, message: "Example: John Doe"},
                                    ]}
                                >
                                    <Input size={'small'} placeholder="John Doe"
                                           prefix={<><UserOutlined/>&nbsp;&nbsp;</>}/>
                                </Form.Item>
                            </Col>

                            <Col xs={24} lg={24}>
                                <Form.Item
                                    label="Credit Card Number"
                                    name="credit_card_number"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter the credit card number!",
                                        },
                                        {
                                            required: true,
                                            message: "We expect a 16 digit credit card number!",
                                            pattern: /^[\d]{16}$/,
                                            transform: value => {
                                                const maskedValue = cardNumberMask(value);
                                                form.setFieldValue('credit_card_number', maskedValue);
                                                return maskedValue.replace(/\s/g, "");
                                            },
                                        },]}
                                >
                                    <Input.Password
                                        size={'small'}
                                        prefix={<><CreditCardOutlined/>&nbsp;&nbsp;</>}
                                        placeholder="xxxx xxxx xxxx xxxx"
                                        maxLength={19}
                                    />
                                </Form.Item>
                            </Col>

                            <Col xs={24} lg={24}>
                                <Row gutter={[8]}>

                                    <Col xs={24} lg={8}>
                                        <Form.Item
                                            name="credit_card_exipation_month"
                                            label="Month"
                                            rules={[{required: true, message: "Please select a month!"}]}
                                        >
                                            <Select size={'small'} placeholder="Month">
                                                <Select.Option value="01">January</Select.Option>
                                                <Select.Option value="02">February</Select.Option>
                                                <Select.Option value="03">March</Select.Option>
                                                <Select.Option value="04">April</Select.Option>
                                                <Select.Option value="05">May</Select.Option>
                                                <Select.Option value="06">June</Select.Option>
                                                <Select.Option value="07">July</Select.Option>
                                                <Select.Option value="08">August</Select.Option>
                                                <Select.Option value="09">September</Select.Option>
                                                <Select.Option value="10">October</Select.Option>
                                                <Select.Option value="11">November</Select.Option>
                                                <Select.Option value="12">December</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} lg={8}>
                                        <Form.Item name="credit_card_exipation_year" label="Year" rules={[{
                                            required: true, message: "Please select a year!"
                                        }]}>
                                            <Select size={'small'} placeholder="Year" style={{width: "100%"}}>
                                                {getYearOptions()}
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} lg={8}>
                                        <Form.Item
                                            label="CVV"
                                            name="credit_card_cvv"
                                            rules={[
                                                {required: true, message: "Please enter the CVV!"},
                                            ]}
                                        >
                                            <Input.Password size={'small'} placeholder="CVV"/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>

                            <Col xs={24} lg={24}>
                                <Form.Item
                                    label={'Email'}
                                    name="billing_email"
                                    rules={[{
                                        // eslint-disable-next-line
                                        pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                                        message: 'Please enter valid email',
                                    }]}
                                >
                                    <Input size={'small'} placeholder="Billing email"
                                           prefix={<><MailOutlined/>&nbsp;&nbsp;</>}/>
                                </Form.Item>
                            </Col>

                            <Divider dashed>
                                <Typography.Title level={5}>
                                    <CompassOutlined/> Billing Address
                                </Typography.Title>
                            </Divider>

                            <Col xs={24} lg={24}>
                                <Form.Item
                                    label="Address"
                                    name="billing_address"
                                    rules={[
                                        {required: true, message: "Important"},
                                    ]}
                                >
                                    <Input size={'small'} placeholder="Billing address"/>
                                </Form.Item>
                            </Col>

                            <Col xs={24} lg={24}>
                                <Row gutter={[16, 16]}>
                                    <Col xs={24} lg={8}>
                                        <Form.Item
                                            label="City"
                                            name="billing_city"
                                            rules={[
                                                {required: true, message: "Important"},
                                            ]}
                                        >
                                            <Input size={'small'} placeholder="Billing city"/>
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} lg={8}>
                                        <Form.Item
                                            name="billing_state"
                                            label="State"
                                            rules={[{required: true, message: "Important"}]}
                                        >
                                            <Select size={'small'} placeholder="Billing state">
                                                {parsedStates.map(state => (
                                                    <Select.Option key={state.postal} value={state.postal}>
                                                        {state.postal}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>


                                    <Col xs={24} lg={8}>
                                        <Form.Item
                                            label="Zip code"
                                            name="billing_zip_code"
                                            rules={[
                                                {required: true, message: 'Important',},
                                                // eslint-disable-next-line
                                                {pattern: /^[\d]{5}$/, message: 'We expect 5 numbers',}
                                            ]}
                                        >
                                            <Input size={'small'} placeholder="Billing zip code" maxLength={5}/>
                                        </Form.Item>
                                    </Col>

                                </Row>
                            </Col>


                        </Row>
                    </Spin>

                    // successful order
                    : isPaymentSuccessful === true
                        ? <Result
                            status="success"
                            title="Successfully Purchased!"
                        />
                        : <Result
                            status="error"
                            title="Transaction failed!"
                            subTitle={transactionError}
                        />
                }
            </AuthorizeNetPaymentForm>

        </Modal>

    );
};

export default PaymentForm;

